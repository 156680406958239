<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Result Columns for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceResultColumns" />
      </v-card-title>

      <v-card-text>      
        Customize the result columns for this race. You can show/hide columns and change the order in which they show on the leaderboards.
      </v-card-text>

      <v-card-text>      
        <strong>Tip:</strong> leave the caption empty to have it automatically translate into the users' language. When you customize the caption of a column it will no longer translate.
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="columns"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Columns</v-toolbar-title>
            <v-spacer/>
            <v-dialog v-if="badges && badges.length > 0" v-model="showDialog" max-width="300px" @input="v => v || closeDialog()">
              <template v-slot:activator="{ on }">
                <v-btn color="accent" dark v-on="on"><v-icon small class="mr-2">fa-plus</v-icon> Add Column</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Add Custom Column</v-card-title>
                <v-card-text style="max-height: 300px;">
                  <v-radio-group v-model="badgeId" column>
                    <v-radio v-for="(item, idx) in badges" :key="idx" :label="item.name" :value="item.id"></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                  <v-btn color="secondary" v-if="badgeId" :loading="$store.getters.isLoading" @click="addBadgeColumn">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:body="props">
          <draggable :list="columns" tag="tbody" handle=".handle" @change="reorderRows">
            <tr v-for="(item, index) in columns" :key="index">
              <td v-if="true">
                <v-icon small class="handle">fa-sort-circle</v-icon>
                <span v-if="false" class="ml-2 text-muted">{{index}}/{{item.rank}}</span>
              </td>
              <td><v-switch v-model="item.visible" :disabled="item.disabled" hide-details @change="syncVisibleMobile(item)"/></td>
              <td><v-switch v-model="item.visible_mobile" :disabled="item.disabled || !item.visible" hide-details /></td>
              <td>
                <span v-if="item.type=='AVATAR'">n/a</span>
                <v-text-field v-else v-model="item.caption" :disabled="!item.visible" hide-details :placeholder="item.caption_key ? $t('results.cols.'+item.caption_key) : (item.caption || '')" />
              </td>
              <td>
                <div :class="item.visible?'':'grey--text'">
                  {{ item.type }}
                  <span v-if="item.badge_id">{{ item.badge_id }}</span>
                  <div v-if="item.hint" class="text-muted">{{ item.hint }}</div>
                </div>
              </td>
            </tr>
          </draggable>
        </template>        
        <template v-slot:item.visible="{ item }">
          <v-switch v-model="item.visible" />
        </template>
        <template v-slot:item.visible_mobile="{ item }">
          <v-switch v-model="item.visible_mobile" :value="item.visible_mobile == null ? item.visible : item.visible_mobile" />
        </template>
        <template v-slot:item.caption="{ item }">
          <span v-if="item.type=='AVATAR'">n/a</span>
          <v-text-field v-else v-model="item.caption" :disabled="!item.visible" />
        </template>
        <template v-slot:item.type="{ item }">
        </template>
      </v-data-table>
      <v-card-text>
        <v-btn color="primary" large @click="save">Save Columns</v-btn>
        <v-btn color="primary" text @click="reset">Reset Columns</v-btn>
        <v-dialog v-model="selectRacesDialog" max-width="400px">
          <template v-slot:activator="{ on }">
            <v-btn text color="primary" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-copy</v-icon> Apply to other races</v-btn>
          </template>
          <v-card>
            <v-card-title>Select races</v-card-title>
            <v-card-text>
              Select the races you want to apply this column configuration to. This will overwrite any current configuration.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <v-checkbox v-for="(item, idx) in otherRacesInEvent" :key="idx" v-model="selectedRaceIds" :label="item.name" :value="item.id" hide-details></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="secondary" text @click="selectRacesDialog = false">{{$t('shared.cancel')}}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" :disabled="selectedRaceIds.length === 0" @click="applyToOtherRaces();selectRacesDialog = false">{{$t('shared.confirm')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>                      
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import draggable from 'vuedraggable'
import resultColumns from '@/util/resultColumns.js'

export default {
  name: "ResultColumns",
  components: {
    Header,
    RaceSelector,
    draggable,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      columns: [],
      badges: [],
      selectedRaceIds: [],
      selectRacesDialog: false,
      showDialog: false,
      badgeId: null,
      siteData: siteData,
      headers: [
        { text: 'Order', value: 'rank', sortable: false },
        { text: 'Visible', value: 'visible', sortable: false },
        { text: 'Mobile', value: 'visible_mobile', sortable: false },
        { text: 'Caption', value: 'caption', sortable: false },
        { text: 'Type', value: 'type', sortable: false},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.badges = (await eventManagerService.getBadges(id, raceId)).data.data;
      const cols = (await eventManagerService.getResultColumns(id, raceId)).data.data || [];

      // http://localhost:8080/eventmanager/5k-a-day-keeps-the-doctor-away/1k-a-day/columns
      this.columns = resultColumns.mergeResultColumns(this.race, cols);
      console.log('cols', cols, 'MERGED', this.columns);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    syncVisibleMobile(item) {
      console.log('syncVisibleMobile', item, item.visible);
    	if (!item.visible) {
        item.visible_mobile = false;
      }
    },

    reorderRows(arg) {
      //arg.moved.element.rank = arg.moved.newIndex + 1;
      //console.log('sort', arg);

      this.$nextTick(() => {
        //console.log('re-applyings ranks now', arg);
        var idx = 1;
        for (const col of this.columns) {
          if (idx != col.rank) {
            //console.log('change rank', col.rank, 'to', idx);
          }
          col.rank = idx;
          idx++;
        }
      });
    },

    async applyToOtherRaces() {
      const response = (await eventManagerService.cloneResultColumns(this.event.id, this.race.id, this.selectedRaceIds)).data;
      this.$helpers.toastResponse(this, response, 'Successfully copied column configuration.');
    },

    openDialog(item) {
      this.showDialog = true
    },

    closeDialog () {
      this.showDialog = false
    },

    async addBadgeColumn() {
      const badge = this.badges.find(x => x.id == this.badgeId);
      this.columns.push({
        type: 'BADGE',
        caption: badge.name,
        badge_id: badge.id,
        visible: true,
        rank: this.columns.length + 1,
      });
      this.closeDialog();
    },

    async reset() {
      if (confirm('Are you sure you want to reset all changes you\'ve made to the result configuration?')){
        await eventManagerService.putResultColumns(this.event.id, this.race.id, []);
        await this.getProfile();
      }
    },

    async save() {
      const modifiedCols = [];
      for (const col of this.columns) {
        console.log('col', col);
        if (col.default != col.visible || col.visible != col.visible_mobile || col.caption != col.default_caption || col.rank != col.default_rank){
          modifiedCols.push(col);
        }
      }

      console.log('Saving', modifiedCols);
      const response = (await eventManagerService.putResultColumns(this.event.id, this.race.id, modifiedCols)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved column configuration.');

      await this.getProfile();
    },

  },
  computed: {
    otherRacesInEvent(){
      return this.event.races.filter(x => x.id != this.race.id);
    },

    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Result Columns', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
.handle {cursor: move;}
</style>

